<template>
    <div class="">
        <Toolbar  
            :click-add="showAdd"
            :click-edit="showEdit"
            :click-delete="showDelete"
            :click-refresh="showRefresh"
            :set-filter="loadDataSearch"
            :status-print="false"
            :data   = "recs"
            :fields = "json_fields"
            type    = "xls"
            name    = "Kunjungan.xls"
        />
        <div class="p-2">
            <div id="wrapper">
                <div id="content">
                    <div class="w3-hide-small w3-hide-medium" id="boundary">
                        <table class="table mb-0 h9 " id="header" cellpadding="4" cellspacing="0" border="0" style="width:100%;">
                            <thead>
                                <tr class="">
                                    <th width="50px">No. </th>
                                    <th v-for="fl in filterByShow" :width="fl.width" :key="fl.name" class="">{{fl.name}}</th>
                                    <th width="1px"></th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="scrlt-y" v-bind:style="'height:'+  (screenHeight-210) +'px;'">
                        <table class="table table-hover table-strip table-bordered">
                            <tr v-for="(dt, index) in recs" :key="dt.ts_id" class="w3-hover-pale-blue w3-small cursor" v-on:dblclick="showEdit(dt, index)" :class="index == selectedRow ?'w3-pale-blue':''">
                                <td class="w3-hide-large" v-on:click="setKlikRow(index, dt)">
                                    <div class="w3-col s4 m2 w3-theme-l5">
                                        <i :class="index == selectedRow ?'fa fa-caret-right':''"></i>ID
                                    </div>
                                    <div class="w3-col s8 m10 w3-theme-l4">
                                        : {{dt.ts_id}}
                                    </div>
                                    <div class="w3-col s4 m2 w3-theme-l5">Nama</div>
                                    <div class="w3-col s8 m10 w3-theme-l4">
                                        : {{dt.ts_name}}
                                    </div>
                                    <div class="w3-col s4 m2 w3-theme-l5">Level</div>
                                    <div class="w3-col s8 m10 w3-theme-l4">
                                        : {{dt.ts_level | setLevel}}
                                    </div>
                                    <div class="w3-col s4 m2 w3-theme-l5">Status</div>
                                    <div class="w3-col s8 m10 w3-theme-l4" v-html="setStatus(dt.ts_status)">
                                    </div>
                                </td>
                                <td width="50px" class="w3-hide-small w3-hide-medium w3-border-right">
                                    <i :class="index == selectedRow ?'fa fa-caret-right':''"></i>&nbsp;{{dt.idx}}
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" :width="findObjFind('width','ts_id')" class="w3-center w3-hide-small w3-hide-medium w3-border-right">
                                    {{dt.ts_id}}
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','ts_name')" class="w3-hide-small w3-hide-medium w3-border-right">
                                    {{dt.ts_name}}
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','ts_level')" class="w3-center w3-hide-small w3-hide-medium w3-border-right">
                                    {{dt.ts_level | setLevel}}</td>
                                <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','ts_status')" class="w3-border-right w3-hide-small w3-hide-medium">
                                    <div style="position: relative;" :style="'width:' + findObjFind('width','ts_status')">
                                        <span v-html="setStatus(dt.ts_status)"></span>
                                        <div v-show="index == selectedRow" style="position: absolute; top: -6px; right: 0px; text-align:center;" class="w3-opacity-off">
                                            <button title="Tampilkan Kunjungan" style="padding: 5px 7px 5px 7px; margin-right:1px;" class="w3-btn w3-circle w3-tiny w3-theme-d3" v-on:click="showKunjungan(dt, index)"><i class="fa fa-search w3-large"></i></button>
                                        </div>
                                    </div>
                                </td>
                            </tr>                                            
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!-- pagging -->
        <div >
            <nav class="Page navigation h9 bg-light bg-gradient shadow">
                <div class="d-flex justify-content-center">
                    <ul class="pagination text-center">
                        <li class="page-item page-item active"><a :class="pages.halaman == 1 ?'dsb':''" class="page-link" @click="first()" href="#">First</a></li>
                        <li class="page-item page-item active"><a :class="pages.halaman == 1 ?'dsb':''" class="page-link" @click="prev()" href="#">«</a></li>
                        <li class="page-item page-item active"><a class="page-link px-0 px-md-2" href="#">Page {{pages.halaman}} sd {{pages.ttlhalaman}}</a></li>
                        <li class="page-item page-item active"><a :class="pages.halaman == pages.ttlhalaman ?'dsb':''" class="page-link" @click="next()" href="#">»</a></li>
                        <li class="page-item page-item active"><a :class="pages.halaman == pages.ttlhalaman ?'dsb':''" class="page-link" @click="last()" href="#">Last</a></li>
                        <li class="page-item page-item active"><a class="page-link  page-item active" href="#">Rec {{pages.ttlrec}}</a></li>
                        </ul>
                </div>
            </nav>
        </div>
        <div id="modal-form" class="modal w3-animate-zoom row col-md-5 mt-4 mt-5 top-50 start-50 translate-middle " style="max-width: 600px;">
            <div class="w3-modal-content modal-content col-md-3">
            <div class="modal-header pb-0"> 
                <button onclick="document.getElementById('modal-form').style.display='none'" 
                class="btn w3-display-topright btn-lg">&times;</button>
                <p class="h9 fw-bold"><i class="mdi mdi-file-document-multiple-outline"></i>&nbsp; Add / Edit data</p>
            </div>
            <div class="modal-body">
                <form class="container" id="frmGdg" v-on:submit.prevent="saveData" autocomplete="off">
                    <div class="mb-3">
                            <label class="form-label h9 fw-bold" for="ts_id">{{findObjFind('name','ts_id')}} :</label>
                            <input ref="ts_id" readonly type="text" class="form-control" id="ts_id" :placeholder="findObjFind('name','ts_id')" v-model="rec.ts_id">
                    </div>
                    <div class="mb-3">
                            <label class="form-label h9 fw-bold" for="ts_name">{{findObjFind('name','ts_name')}} :</label>
                            <input ref="ts_name" required type="text" class="form-control" id="ts_name" :placeholder="findObjFind('name','ts_name')" v-model="rec.ts_name">
                    </div>
                    <div class="mb-3">
                            <label class="form-label h9 fw-bold" for="ts_password">{{findObjFind('name','ts_password')}} :</label>
                            <div class="input-group">
                                <input v-model="rec.password" class="form-control" :type="lihat?'text':'password'" :placeholder="findObjFind('name','ts_password')">
                            <span v-on:click="lihat=!lihat" type="button" class="input-group-text"><i class="fa" :class="lihat?'fa-eye':'fa-eye-slash'"></i></span>
                            </div>
                    </div>
                    <div class="mb-3">
                            <label class="form-label h9 fw-bold" for="ts_level">{{findObjFind('name','ts_level')}} :</label>
                        
                            <select v-model="rec.ts_level" class="form-select" required>
                                <option class="w3-white" v-for="option in userlevel" v-bind:value="option.value" :key="option.value">
                                    {{ option.text }}
                                </option>
                            </select>
                    </div>
                    <div class=" form-check">  
                        <input class="form-check-input" v-model="rec.ts_status" type="checkbox" true-value="T" false-value="F">
                        <label class="form-check-label h5 fw-bold">{{findObjFind('name','ts_status')}}</label>  
                    </div>
                </form>
            </div>
            <div class="modal-footer"  style="margin-top: 15px;">
                <h6 class="w3-right">
                <button form="frmGdg" class="btn btn-success rounded" type="submit"><i class="fa fa-save"></i> Save</button>
                <button type="button" class="btn btn-danger rounded ms-2" onclick="document.getElementById('modal-form').style.display='none'"><i class="fa fa-close"></i> Close</button>
                </h6>
            </div>
            </div>
        </div>
        <div id="modal-kunjungan" class="modal w3-animate-zoom row col-md-5 mt-4 mt-5 top-50 start-50 translate-middle " style="max-width: 900px;">
            <div class="w3-modal-content modal-content col-md-3">
                <div class="modal-header pb-0"> 
                    <button onclick="document.getElementById('modal-kunjungan').style.display='none'" 
                    class="btn w3-display-topright btn-lg">&times;</button>
                    <p class="h9 fw-bold"><i class="mdi mdi-file-document-multiple-outline"></i>&nbsp; Add / Edit data</p>
                </div>
                <div class="modal-body">
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li class="nav-items">
                            <button class="nav-link" @click="tab=1" :class="tab==1 ? 'nav-link active ' : ''"><i class="fa fa-calendar">&nbsp;</i> Harian</button>
                        </li>
                        <li class="nav-items">
                            <button class="nav-link" @click="tab=2" :class="tab==2 ? ' nav-link active ' : ''"><i class="fa fa-calendar">&nbsp;</i> Bulanan</button>
                        </li>
                    </ul>
                    
                    <div class="tab-content">
                        <div class="tab-pane fade show active" v-if="tab==1">
                            <div class="mb-3">
                                <form class="container" v-on:submit.prevent="getKunjunganHari" autocomplete="off"  style="margin-bottom: 10px;">
                                    <div class="row align-items-end">
                                        <div class="row col-md-10">
                                            <div class="col-md-6">
                                                <label class="h9 text-dark form-label fw-bold" for="floatingInput">Start :</label>
                                                <div class="input-group mb-3">
                                                    <input required type="text" class="form-control" id="k_start" placeholder="Tanggal" v-model="krec.k_start">
                                                    <span v-on:click="showdate.k_start=true" type="button" class="input-group-text"><i class="fa fa-calendar"></i></span>
                                                  </div>
                                                  <transition name="calendar-fade">
                                                      <date-picker @close="showdate.k_start = false" v-if="showdate.k_start" v-model="krec.k_start"></date-picker>
                                                  </transition>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="h9 text-dark form-label fw-bold" for="floatingInput">End :</label>
                                                <div class="input-group mb-3">
                                                    <input required type="text" class="form-control" id="k_end" placeholder="Tanggal" v-model="krec.k_end">
                                                    <span v-on:click="showdate.k_end=true" type="button" class="input-group-text"><i class="fa fa-calendar"></i></span>
                                                  </div>
                                                  <transition name="calendar-fade">
                                                    <date-picker @close="showdate.k_end = false" v-if="showdate.k_end" v-model="krec.k_end"></date-picker>
                                                </transition>
                                            </div>
                                        </div>
                                        <div class="col-md-2 mb-3">
                                            <button type="submit" class="btn btn-primary" form="frmKunjunganHari"><i class="fa fa-search"></i>&nbsp; Show</button>
                                        </div>
                                    </div>
                                </form>
                                <div class="container">
                                    <table class="table mb-0 h9 table-hover table-strip table-bordered" id="reckunjunganhari">
                                        <thead>
                                            <tr class="h9">
                                                <th class="w3-border-right">Name</th>
                                                <th class="w3-border-right">Location</th>
                                                <th class="w3-border-right">Date & Time</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade show active" v-if="tab==2">
                            <div class="mb-3">
                                <form class="container" id="frmKunjunganBulan" v-on:submit.prevent="getKunjunganBulan" autocomplete="off" style="margin-bottom: 10px;">
                                    <div class="row align-items-end justify-content-center">
                                        <div class="col-md-5">
                                                 <label class="form-label">
                                                    Bulan :
                                                </label>
                                                <select class="form-select" v-model="krec.k_date">
                                                    <option value=""></option>
                                                    <option value="01">Januari</option>
                                                    <option value="02">Februari</option>
                                                    <option value="03">Maret</option>
                                                    <option value="04">April</option>
                                                    <option value="05">Mei</option>
                                                    <option value="06">Juni</option>
                                                    <option value="07">Juli</option>
                                                    <option value="08">Augustus</option>
                                                    <option value="09">September</option>
                                                    <option value="10">Oktober</option>
                                                    <option value="11">November</option>
                                                    <option value="12">Desember</option>
                                                </select>
                                        </div>
                                        <div class="col-md-2">
                                            <button type="submit" class="btn btn-primary" form="frmKunjunganBulan"><i class="fa fa-search"></i>&nbsp; Show</button>
                                        </div>
                                    </div>
                                </form>
                                <div class="container">
                                    <table class="table mb-0 h9 table-hover table-strip table-bordered" id="reckunjunganbulan">
                                        <thead>
                                            <tr class="h9">
                                                <th class="w3-border-right">Name</th>
                                                <th class="w3-border-right">Location</th>
                                                <th class="w3-border-right">Date & Time</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import Config from '@/conf/Config.js';
import axios from "axios";
import Toolbar from "@/components/Toolbar.vue";
import DatePicker from "@/components/DatePicker.vue";
import moment from "moment";

// import "jquery/dist/jquery.min.js";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-buttons-bs5/js/buttons.bootstrap5"
import "datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css"
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";

export default {
    // head: {
    //     title: {
    //       inner: 'Main'
    //     }
    // },
    name : 'Kunjungan',
    components: {
        Toolbar,
        DatePicker
    },
    data() {
        return {
            lihat : false,
            tab : 1,
            showdate :{k_start:false, k_end:false},
            screenHeight : 0,
            selectedRow : -1,
            rec: {},
            krec : {
                k_start : moment(new Date()).format('YYYY-MM-DD'),
                k_end : moment(new Date()).format('YYYY-MM-DD'),
                k_date : ""
            },
            harirecs : [],
            bulanrecs : [],
            recs: [],
            userlevel: [
                { text: 'SALES', value: '00' },
                { text: 'MASTER', value: '01' },
                { text: 'STAPEL', value: '10' },
                { text: 'ADMIN', value: '11' }
            ],
            pages : {halaman : 1, batas : 0, ttlhalaman : 0, ttlrec:0, limit:Config.perpage, search:''},
            filters : {
            	operator : 'AND',
                fields :[
                        {fieldFind : '', key : 'ts_id', type: 'text', name : 'ID', filter : true, width : '50px', grid : true},
                        {fieldFind : '', key : 'ts_name', type: 'text', name : 'Name / Username', filter : true, width : 'auto', grid : true},
                        {fieldFind : '', key : 'ts_password', type: 'text', name : 'Password', filter : true, width : 'auto', grid : false},
                        {fieldFind:'', key : 'ts_level', type: 'select', name : 'Level', filter : true, width : '80px', grid : true, valueFind: [{key:'T',label:'Aktif'},{key:'F',label:'Tidak Aktif'}]},
                        {fieldFind:'', key : 'ts_status', type: 'select', name : 'Status', filter : true, width : '50px', grid : true, valueFind: [{key:'T',label:'Aktif'},{key:'F',label:'Tidak Aktif'}]},
                ],
                find : []   
            },
            /**Untuk transfer excel*/
            json_fields : {
                'ID' : 'ts_id',
                'NAME' : 'ts_name',
                'KETERANGAN' : 'ts_keterangan'
            },
        }
    },
    methods : {
        findObjFind(key, val){
            return this.findObj(this.filters.fields,'key',val)[key];
        },
        findField(val){
            return this.findObj(this.fieldRules, 'field',val);
        },
        findObj(obj, key, value) {
            let ketemu = 0;
            for (var pkey in obj) {
                if (obj[pkey][key] == value) {
                    break;
                } else {
                    ketemu++;
                }
            }
            if(obj[ketemu]!=undefined){
                return obj[ketemu];
            }else
                return null;
        },
        first(){
            let self = this;
            self.pages.halaman = 1;
            self.loadData();
        },
        prev(){
            let self = this;
            self.pages.halaman = self.pages.halaman - 1;
            self.loadData();
        },
        next(){
            let self = this;
            self.pages.halaman = self.pages.halaman + 1;
            self.loadData();
        },
        last(){
            let self = this;
            self.pages.halaman = self.pages.ttlhalaman;
            self.loadData();
        },
        setKlikRow(index, dt){
            let self = this;
            self.selectedRow = index;
            dt.password = '';
            self.rec = Object.assign({}, dt);
        },
        loadData(){
            this.screenHeight = window.innerHeight;
            let self = this;
            self.selectedRow = -1;
            self.rec = {};
            let params = {
                pfunction:'show',
                limit: self.pages.limit,
                page: self.pages.halaman,
                search: self.pages.search
            }
            axios.post("sales/ApiSales.php",params).then(function (response) {
                let idx = (self.pages.limit*self.pages.halaman)-self.pages.limit+1;
                self.pages.ttlrec = parseFloat(response.data.ttlrec);
                self.pages.ttlhalaman = Math.ceil(parseFloat(response.data.ttlrec)/self.pages.limit);
                response.data.rec.map(function(val){
                    val['idx'] = idx;
                    idx++;
                });
                self.recs = response.data.rec;
            });
        },
        showRefresh(){
            this.pages.search = "";
            this.loadData();
        },
        loadDataSearch(filter){
            this.pages.search = filter;
            this.loadData();
        },
        async showAdd() {
            let self = this;
            self.selectedRow = -1;
            self.stsAdd = true;
            let key = await axios.post("admin/ApiGetId.php",{
                pfunction : 'getIdTable',
                tablename : 'tb_sales'
            });
            self.rec = {
                ts_id : key.data+1,
                ts_name : '',
                ts_password : '',
                password : '',
                ts_level : '00',
                ts_status : 'T'
            };
            document.getElementById("modal-form").style.display = "block";
            setTimeout(function(){
                document.getElementById('ts_name').focus();
            },500);
        },
        showKunjungan(dt,index){
            document.getElementById("modal-kunjungan").style.display = "block";
            $('#reckunjunganhari').DataTable().clear();
            setTimeout(function(){
                $('#reckunjunganhari').DataTable().destroy();
            },500);
        },
        showEdit(){
            let self = this;
            if (self.selectedRow < 0) {
                this.$toast.error('pilih gudang.!');
                return false;
            }else{
                this.stsAdd = false;
                document.getElementById('modal-form').style.display='block';
                setTimeout(function(){
                    document.getElementById('ts_name').focus();
                },500);
            }
        },
        saveData(event){
            var self = this;
            if (self.stsAdd == true) {
                self.rec.pfunction = "add";
            }else{
                self.rec.pfunction = "edit";
            }
            self.rec['userentry'] = localStorage.username;
            axios.post("sales/ApiSales.php",self.rec).then(function (response) {
                if(response.data.success == true){
                    self.$toast.success(response.data.msg);
                    if (self.stsAdd) {
                        self.recs.splice(0, 0, self.rec);
                    }else{
                        self.recs[self.selectedRow] = self.rec;
                    }
                    document.getElementById('modal-form').style.display='none';
                    self.selectedRow = -1;
                }else{
                    self.$toast.error(response.data.msg);
                }
            });
            event.preventDefault();
            return false;
        },
        getKunjunganHari(event){
            let self = this;
            document.getElementById('idLoading').style.display='block';
            let vparams = {
                sales : self.rec.ts_id,
                start : self.krec.k_start,
                end : self.krec.k_end,
                pfunction : 'showbyidhari'
            }
            axios.post("sales/ApiSalesLocation.php",vparams).then(function (response) {
                document.getElementById('idLoading').style.display='none';
                self.harirecs = response.data.rec;
                $('#reckunjunganhari').DataTable({
                    destroy: true,
                    responsive: true,      
                    scrollX: true,
                    deferRender: true,
                    autoWidth: false,
                    data : self.harirecs,
                    columns: [
                        {"data": "ts_name"},
                        {"data": "tc_name"},
                        {"data": "sl_date"}
                    ],
                    columnDefs: [
                    ],
                    dom: "<'d-flex dlex-row flex-nowrap mb-3 align-items-center p-0'<'h9 col-md-6'l><'h9 col-md-6'f>>" +
                    "<'col-md-6'B>" +
        "<'row'<'col-md-12 h9 mb-3'tr>>" +
        "<'row'<'col-12 col-md-5 h9'i><'col-12 col-md-7'p>>",
                    buttons: [
                        { 
                            extend: 'csvHtml5', text: 'Export',
                            className: 'w3-btn w3-blue w3-tiny w3-round',                        
                        }
                    ],
                });
            });
            event.preventDefault();
            return false;
        },
        getKunjunganBulan(event){
            let self = this;
            document.getElementById('idLoading').style.display='block';
            let vparams = {
                sales : self.rec.ts_id,
                bulan : self.krec.k_date,
                pfunction : 'showbyidbulan'
            }
            axios.post("sales/ApiSalesLocation.php",vparams).then(function (response) {
                document.getElementById('idLoading').style.display='none';
                self.bulanrecs = response.data.rec;
                $('#reckunjunganbulan').DataTable({
                    destroy: true,
                    responsive: true,      
                    scrollX: true,
                    deferRender: true,
                    autoWidth: false,
                    data : self.bulanrecs,
                    columns: [
                        {"data": "ts_name"},
                        {"data": "tc_name"},
                        {"data": "sl_date"}
                    ],
                    columnDefs: [
                    ],
                    dom: "<'d-flex dlex-row flex-nowrap mb-3 align-items-center p-0'<'h9 col-md-6'l><'h9 col-md-6'f>>" +
                    "<'col-md-6'B>" +
        "<'row'<'col-md-12 h9 mb-3'tr>>" +
        "<'row'<'col-12 col-md-5 h9'i><'col-12 col-md-7'p>>",
                    buttons: [
                        { 
                            extend: 'csvHtml5', text: 'Export',
                            className: 'w3-btn w3-blue w3-tiny w3-round',                        
                        }
                    ],
                });
            });
            event.preventDefault();
            return false;
        },
        showDelete() {
            var self = this;
            if (self.selectedRow < 0) {
                this.$toast.error('pilih gudang.!');
                return false;
            }else{
                swal(
                    {
                        title: "Yakin Ingin Menghapus Data ..!",
                        text: "Data Yang sudah di hapus tidak akan bisa dikembalikan",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Ya, Hapus Data",
                        cancelButtonText: "Tidak, Batal Hapus",
                        closeOnConfirm: false,
                        closeOnCancel: true,
                    },
                    function(isConfirm) {
                        if (isConfirm) {
                            var vparams = { 
                                ts_id : self.rec.ts_id,
                                pfunction : "delete"
                            };
                            axios.post("sales/ApiSales.php",vparams).then(function (response) {
                                if (response.data.success == true) {
                                    swal({title: "Hapus..!",text: "Data Sudah terhapus",timer: 1000,type: "success"});
                                    self.$delete(self.recs, self.selectedRow);
                                }else{
                                    swal({title: "Delete",text: "Gagal delete data",type: "error",html: true});
                                }
                            });
                        }
                    }
                );
            }
        },
        setStatus(dt) {
            if (dt === "T") {
                return "<div class='btn btn-success btn-sm'><p class='h7 p-0 m-0'>Active</p></div>";
            } else {
                return "<div class='btn btn-warning btn-sm'><p class='h7 p-0 m-0'>InActive</p><div>";
            }
        },
        setInit() {
            let self = this;
            axios.post("sales/ApiSales.php",{
                pfunction : 'setcustomer'
            }).then(function (response) {
                self.customers = response.data.rec;
            });
        }
    },
    filters: {
        toRp(value) {
            return `${Intl.NumberFormat("de-DE").format(value)}`;
        },
        pNumber(num) {
            var x = num.replace(/\D/g, '').match(/(\d{0,4})(\d{0,4})(\d{0,4})/);
            return !x[2] ? x[1] : '(' + x[1] + ')-' + x[2] + (x[3] ? '' + x[3] : '');
        },
        setLevel(dt) {
            if (dt == "11") {
                return "ADMIN";
            } else if (dt == "10") {
                return "STAPEL";
            } else if (dt == "01") {
                return "MASTER";
            } else {
                return "SALES";
            }
        }
    },
    watch: {
        // 'tab': function(dt){
        //     if (dt == 1) {
        //         $('#recmarketing').DataTable().destroy();
        //     }else{        
        //         $('#recstock').DataTable().destroy();
        //     }
        // }
    },
    computed :{
        filterByShow(){
            return  this.filters.fields.filter(event => {
                return event.grid == true;                
            });
        }
    },
    mounted(){
        this.screenHeight = window.innerHeight;
        // this.setInit();
        this.loadData();
    }
};
</script>